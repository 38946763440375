import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {PoolsInterface, RetrieveModelInterface} from "interfaces/retrieve";
import { RootState } from "app/store";
import configuration from "components/features/Retrieve/configuration";
import {getCustomerCode} from "app/utils";

interface RetrieveState {
  model: RetrieveModelInterface;
}

const initialState: RetrieveState = {
  model: {
    fromPrice: 0,
    activePool: "",
    origin: configuration[getCustomerCode()].origins.defaultOrigin,
    selectedPoolData: undefined
  }
};

export const retrieveSlice = createSlice({
  name: "retrieve",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRetrieveModel: (state, action: PayloadAction<Partial<RetrieveModelInterface>>) => {
      state.model = {
        ...state.model,
        ...action.payload
      };
    },
    resetRetrieve: (
        state
    ) => {
      state.model = initialState.model;
    }
  },
});

export const {
  setRetrieveModel,
  resetRetrieve
} = retrieveSlice.actions;

export const selectRetrieveModel = (state: RootState) => state.retrieve.model;

export default retrieveSlice.reducer;
