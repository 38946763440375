import {DetailsModelInterface, TravelDetailsInterface} from "interfaces/details";

interface DetailsConfig {
    [key: string]: {
        model: DetailsModelInterface,
        travelDetails: TravelDetailsInterface;
        alternateFlights: {
            hasPriceComparisonTable: boolean;
        };
        yourSelection: {
            hasAsterisk: boolean;
        }
    }
}

const defaultModel: DetailsModelInterface = {
    origin: "",
    pool: "",
    numAdt: 1,
    numChd: 0,
    numInf: 0,
    minStay: 1,
    maxStay: 2,
    dayType: "",
    earliestOut: "",
    latestRet: "",
    timeRestriction: "",
    travelClass: "ECONOMY",
    isTravelingBusiness: false,
    poolDestinationsData: [],
    poolFromPrice: 0,
    imageFileName: "",
    availableStayValues: [],
    totalPrice: {
        amount: 0,
        currency: "EUR"
    },
    filterData: {
        maxStay: {
            start: 1,
            end: 4,
            titleKey: false,
        },
        minStay: {
            start: 1,
            end: 4,
            titleKey: false,
        },
        numAdt: {
            start: 1,
            end: 9,
            titleKey: false,
        },
        numChd: {
            start: 0,
            end: 8,
            titleKey: false,
        },
        numInf: {
            start: 0,
            end: 1,
            titleKey: false,
        },
        dayType: {
            values: ["FRI_SUN", "SAT_MON", "SATURDAY"],
            emptyTopLabel: "FULLFLEX",
        },
        timeRestriction: {
            values: ["NOTEARLY", "LATEFLIGHTS"],
            emptyTopLabel: "FULLFLEX",
        },
        travelClass: {
            values: ["ECONOMY", "BUSINESS"],
        },
    },
};

const defaultTravelDetails: TravelDetailsInterface = {
    hasTravelClass: true,
    departureBuffer: 0,
    returnBuffer: 2,
    maxPax: 9,
    minMaxDiff: 3,
    adtNumLabel: "numAdt_plural",
    chdNumLabel: "numChd_plural",
    infNumLabel: "numInf_plural",
    hasWeekendOptionsModal: false
}

const configuration: DetailsConfig = {
    ewg: {
        model: {
            ...defaultModel,
            minStay: 2,
            maxStay: 3,
            travelClass: "",
            filterData: {
                ...defaultModel.filterData,
                maxStay: {
                    ...defaultModel.filterData.maxStay,
                    end: 12
                },
                minStay: {
                    ...defaultModel.filterData.minStay,
                    end: 9
                }
            }
        },
        travelDetails: {
            hasTravelClass: false,
            departureBuffer: 0,
            returnBuffer: 7,
            maxPax: 9,
            minMaxDiff: 3,
            adtNumLabel: "numAdt_plural",
            chdNumLabel: "numChd_plural",
            infNumLabel: "numInf_plural",
            hasWeekendOptionsModal: true
        },
        alternateFlights: {
            hasPriceComparisonTable: false,
        },
        yourSelection: {
            hasAsterisk: true
        }
    },
    lhs: {
        model: {
            ...defaultModel,
            filterData: {
                ...defaultModel.filterData,
                numAdt: {
                    ...defaultModel.filterData.numAdt,
                    titleKey: "numAdt"
                },
                numChd: {
                    ...defaultModel.filterData.numChd,
                    titleKey: "numChd"
                },
                numInf: {
                    ...defaultModel.filterData.numInf,
                    titleKey: "numInf"
                }
            }
        },
        travelDetails: {
            ...defaultTravelDetails,
            adtNumLabel: undefined,
            chdNumLabel: undefined,
            infNumLabel: undefined
        },
        alternateFlights: {
            hasPriceComparisonTable: true,
        },
        yourSelection: {
            hasAsterisk: false
        }
    },
    oss: {
        model: defaultModel,
        travelDetails: defaultTravelDetails,
        alternateFlights: {
            hasPriceComparisonTable: true,
        },
        yourSelection: {
            hasAsterisk: false
        }
    }
}

export default configuration;
